<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h2 class="card-title">Generierte Dokumente</h2>
          </div>

          <div class="card-body">
            <table class="table table-striped table-sm table-bordered">
              <thead class="thead-light">
                <th>Datum</th>
                <th>Name</th>
                <th>Betrag</th>
                <th>DATEV Export Datum</th>
                <th>Aktionen</th>
              </thead>

              <tbody>
                <tr v-for="document in order.documents" :key="document.id">
                  <td>{{ document.created_at }}</td>
                  <td><a target="_blank" :href="url + '/api/v1/download/' + document.document.key + '/' + document.hash">{{ document.document.name }} {{ document.number}}</a></td>
                  <td>{{ document.amount | toCurrency }}</td>
                  <td>-</td>
                  <td>
                    <!-- <button class="btn btn-danger btn-sm mr-1"><i class="fas fa-trash"></i></button>        -->
                    <button class="btn btn-info btn-sm mr-1" v-if="$auth.check('orders.email') && order.rsu == null"><i class="fas fa-envelope"></i></button>
                    <button v-if="document.document.key == 'picklist'" class="btn btn-success btn-sm" @click="updateDocument(document.id)"><i class="fas fa-redo"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


    <!-- <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h2 class="card-title">Dokumente generiere</h2>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Dokumenten-Typ:</label>
                  <select v-model="document_type" class="form-control">
                    <option value="invoice">Rechnung</option>
                    <option value="picklist">Pickliste</option>
                  </select>
                </div>
                
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-sm btn-primary" @click="createDocument">Dokument erzeugen</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'OrderDetailsDocuments',
  props: [
      'order'
  ],

  data(){
    return {
      url: process.env.VUE_APP_DOWNLOAD_URL,
      document_type: 'invoice'
    }
  },

  methods:{
    createDocument(){

    },

    updateDocument(id)
    { 
      this.$Progress.start();
      this.axios
          .put("/orders/documents/" + id, {
          })
          .then(() => {
              // this.billingStates = response.data.data;
              this.$swal({
                                    icon: "success",
                                    title: "Dokument wurde neu generiert",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
              this.$parent.getOrder();
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },

    downloadInvoice(){
        this.axios
            .get("/orders/"+this.order.id+"/download/invoice", {responseType: 'arraybuffer'})
            .then(response => {
              console.log(response.data)
              this.downloadFile(response, this.order.invoice.media.name)
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufennnnn",
                });
            });
    },

    downloadFile(response, filename){
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = filename + '.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
    },
  }

}
</script>

<style>

</style>